import './root.css';
import { useSelector } from 'react-redux';

import { ConfigProvider } from 'antd';

import Router from "./router/Router";
import { AccessControl } from 'context/access-control.context';
import { User } from 'context/user.context';
import { useState } from 'react';
import { useEffect } from 'react';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { GetLoggedInUserPermissions } from 'services/api/roles-and-permissions';
import { GetUserApiService } from 'services/api';
import { ViewAdminUsersApiService } from 'services/api/admin-users';

export default function App() {
  const customise = useSelector(state => state.customise)
  const [accessControlData, setAcccessControlData] = useState({
    role: '',
    permissions: []
  });

  const [user, setUser] = useState({});
  const [userRefreshKey, setUserRefreshKey] = useState(0);

  const role = useLocalStorage('role');

  useEffect(() => {
     if (role) {
        GetLoggedInUserPermissions().then(r => {
           setAcccessControlData({
              role: role,
              permissions: r.data,
           });
        });
     } else {
        setAcccessControlData({
           role: '',
           permissions: [],
        });
     }
  }, [role]);

  useEffect(async () => {
     setUser({});
     if (role) {
        const response = await GetUserApiService();
        const user = {
           ...response,
           profile_details: response?.faculty_detail || {},
        };

        if (role !== 'faculty') {
           const adminResponse = await ViewAdminUsersApiService(user.id);
           const adminUser = adminResponse?.data;
           const updatedUser = {
              ...adminUser,
              profile_details: adminUser?.admin_detail || {},
           };
           setUser(updatedUser);
        } else {
           setUser(user);
        }
     }
  }, [role, userRefreshKey]);
  

  return (
    <AccessControl.Provider value={accessControlData}>
      <User.Provider 
        value={{
          ...user,
          refresh: () => setUserRefreshKey(v => v + 1)
        }}
      >
        <ConfigProvider direction={customise.direction}>
            <Router />
        </ConfigProvider>
      </User.Provider>
    </AccessControl.Provider>
  );
}